<template>
  <div>
    <div class="moreInfo">
      <p><label for="">收件人:</label> {{ content.viewEmail }}</p>
      <p><label for="">抄送:</label> {{ cc }}</p>
      <p><label for="">发件人:</label> {{ userInfo.email }}</p>
    </div>
    <div class="inner" contenteditable="">
      <div
        style="line-height:2;font-size:15px;margin: 16px;padding: 0 30px 30px;box-shadow:0px 0px 10px 1px #cccccc;color:#000;"
      >
        <div style="text-align:center;">
          <img
            src="https://rockmind-next-1259377074.cos.ap-guangzhou.myqcloud.com/202008/1596596605129.png"
            width="720"
          />
        </div>
        <div style="text-align:center;">
          <span style="font-size:24px;color:#ecb745"
            >{{ company.name }} 面试通知</span
          >
        </div>
        <div style>
          尊敬的
          <span style="font-weight:bold;">{{ talent.name }}</span>
          （先生/女士）：
        </div>
        <div style="height:30px;">&nbsp;</div>
        <div style="padding: 0 0 0 30px;">
          <div>您好！</div>
          <div>
            非常高兴的通知您，由我们推荐您应聘
            <span style="font-weight:bold;">{{ company.name }}</span> 【
            <span style="color:#ecb745;font-weight:bold;">{{ post.name }}</span>
            】的职位，现简历已通过初步筛选，客户诚邀您参加{{ viewType }}。
          </div>
          <div style="height:30px;">&nbsp;</div>
          <div style="color:#ecb745;font-weight:bold;">面试安排详情：</div>
          <div style="height:30px;">&nbsp;</div>
          <div class="">
            <span
              style="color:#517ddf;font-weight:bold;display:inline-block;min-width: 90px;"
            >
              面试时间：</span
            >{{ viewTime }}
          </div>
          <div class="" v-if="viewType">
            <span
              style="color:#517ddf;font-weight:bold;display:inline-block;min-width: 90px;"
              >面试方式：</span
            >{{ viewType }}
          </div>
          <div class="" v-if="content.viewAddress">
            <span
              style="color:#517ddf;font-weight:bold;display:inline-block;min-width: 90px;"
              >面试地址：</span
            >{{ content.viewAddress }}
          </div>
          <div class="" v-if="content.viewLink">
            <span
              style="color:#517ddf;font-weight:bold;display:inline-block;min-width: 90px;"
              >面试链接：</span
            >{{ content.viewLink }}
          </div>
          <div class="" v-if="content.viewUser.name">
            <span
              style="color:#517ddf;font-weight:bold;display:inline-block;min-width: 90px;"
              >联系人：</span
            >{{ content.viewUser.name }} {{ content.viewUser.mobile }}
          </div>
          <div class="" v-if="content.viewInfo">
            <span
              style="color:#517ddf;font-weight:bold;display:inline-block;min-width: 90px;"
              >附加信息：</span
            ><div
              v-html="longTextFormat(content.viewInfo)"
              style="padding: 0 0 0 20px"
            ></div>
          </div>
          <div style="height:30px;">&nbsp;</div>
          <div class="">
            如您在面试赴约过程中有任何不清楚的地方，请及时联系我。
          </div>
          <div class="">再次祝您面试顺利。</div>
        </div>

        <div style="height:30px;">&nbsp;</div>
        <div style="text-align:right;">
          <img
            src="https://rockmind-next-1259377074.cos.ap-guangzhou.myqcloud.com/202008/1596596630544.png"
            width="150"
          />
        </div>
        <div style="text-align:right;">{{ now }}</div>
        <div style="height:30px;">&nbsp;</div>
        <hr />
        <div style="height:30px;">&nbsp;</div>
        <div style="font-weight:bold;">{{ userInfo.name }}</div>
        <div style="height:30px;">&nbsp;</div>
        <img
          src="https://rockmind-next-1259377074.cos.ap-guangzhou.myqcloud.com/202008/1596596630544.png"
          width="150"
        />
        <div>深圳市洛克蒙德人力资源服务有限公司</div>
        <div>手机：{{ userInfo.mobile }}</div>
        <div>邮箱：{{ userInfo.email }}</div>
        <div>地址：广东省深圳市南山区粤海街道高新技术产业园区R3-B栋3楼3F</div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '../common/compoment'
export default {
  name: 'Uinterview',
  mixins: [mixin],
  props: {
    content: Object
  },
  computed: {
    viewType() {
      if (this.content.viewType) {
        let ret = this.enumOptions.interviewType.find(
          n => n.value === this.content.viewType
        )
        return ret ? ret.label : ''
      }
      return ''
    },
    cc() {
      if (this.content && this.content.viewCopyEmail) {
        return this.content.viewCopyEmail
      }
      return ''
    },
    fileName() {
      if (this.content && this.content.file) {
        return this.content.file.split('/')[1]
      }
      return ''
    },
    recommendUser() {
      return this.content && this.content.recommendUser
        ? this.content.recommendUser
        : {}
    },
    post() {
      return this.content && this.content.post ? this.content.post : {}
    },
    talent() {
      return this.content && this.content.talent ? this.content.talent : {}
    },
    contact() {
      return this.content && this.content.contact ? this.content.contact : {}
    },
    company() {
      return this.content && this.content.company ? this.content.company : {}
    },
    viewTime() {
      return this.$dayjs(this.content.viewTime).format('YYYY年MM月DD日 HH:mm')
    },
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  data() {
    return {
      now: this.$dayjs().format('YYYY年MM月DD日')
    }
  },
  methods: {
    longTextFormat(text) {
      let outText = text.replace(/[\n\r]/g, '<br>')
      return outText
    }
  }
}
</script>

<style lang="less">
.file {
  padding: 16px;
}
.moreInfo {
  line-height: 36px;
  padding: 0 16px;

  label {
    display: inline-block;
    width: 76px;
  }
}
</style>
